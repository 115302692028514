<template>
  <div>
    <div class="white-overlay"></div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addpersonModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Custom.PersonnelSelection')}}</div>

          <div class="modal-body">
            <div class="filter-block">
              <div class="filter-field">
                <span class="title"> {{i18n('Custom.CompanyBy')}} </span>

                <treeselect
                  :options="compName"
                  v-model="optionInfo.CompName"
                  :multiple="true"
                >
                  <div slot="value-label" slot-scope="{ node }">
                    {{ node.raw.customLabel }}
                  </div>
                </treeselect>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Basic.ChineseName')}}/{{i18n('Custom.JobNumber')}} </span>
                <input
                  type="type"
                  name="name"
                  value=""
                  v-model="optionInfo.EmpCName"
                />
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Basic.Age')}} </span>
                <div class="flex">
                  <input
                    class="age-input"
                    type="type"
                    name="name"
                    value=""
                    v-model="optionInfo.AgeMin"
                  />
                  -
                  <input
                    class="age-input"
                    type="type"
                    name="name"
                    value=""
                    v-model="optionInfo.AgeMax"
                  />
                </div>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Custom.Seniority')}} </span>
                <div class="flex">
                  <input
                    class="year-input"
                    type="type"
                    name="name"
                    value=""
                    v-model="optionInfo.SeniorityMin"
                  />
                  -
                  <input
                    class="year-input"
                    type="type"
                    name="name"
                    value=""
                    v-model="optionInfo.SeniorityMax"
                  />
                </div>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Custom.PersonnelSubscope')}} </span>

                <treeselect
                  :options="r2Id"
                  v-model="optionInfo.BTEXT"
                  :multiple="true"
                >
                  <div slot="value-label" slot-scope="{ node }">
                    {{ node.raw.customLabel }}
                  </div>
                </treeselect>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Basic.Department')}} </span>

                <treeselect
                  style="width:400px;"
                  :options="departments"
                  v-model="optionInfo.DeptSName"
                  :multiple="true"
                >
                  <div slot="value-label" slot-scope="{ node }">
                    {{ node.raw.customLabel }}
                  </div>
                </treeselect>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Custom.JobTitle')}} </span>

                <treeselect
                  :options="titleName"
                  v-model="optionInfo.TitleName"
                  :multiple="true"
                >
                  <div slot="value-label" slot-scope="{ node }">
                    {{ node.raw.customLabel }}
                  </div>
                </treeselect>
              </div>

              <div class="filter-field">
                <span class="title"> {{i18n('Custom.Qualification')}} </span>

                <treeselect
                  :options="post"
                  v-model="optionInfo.Post"
                  :multiple="true"
                >
                  <div slot="value-label" slot-scope="{ node }">
                    {{ node.raw.customLabel }}
                  </div>
                </treeselect>
              </div>

              <div class="filter-btn-wrap">
                <a class="btn btn-yellow mr-2" @click.prevent="searchPerson"
                  >{{i18n('Basic.Search')}}</a
                >
                <a class="btn btn-danger mr-2" @click.prevent="clearSearch"
                  >{{i18n('Custom.Clear')}}</a
                >
                <a class="btn btn-blue" href="#" @click.prevent="addOption"
                  >{{i18n('Custom.SaveAsFavorites')}}</a
                >
              </div>
            </div>
            <div class="item-select">
              <div id="left-item">

                <div class="filter-btn-wrap">
                  <label class="option">
                    <input
                      type="radio"
                      name="filter-option"
                      value="employee"
                      checked
                      v-model="select_status"
                    />
                    <span>{{i18n('Custom.Staff')}}</span>
                  </label>
                  <label class="option">
                    <input
                      type="radio"
                      name="filter-option"
                      value="commonly"
                      v-model="select_status"
                    />
                    <span>{{i18n('Custom.CommonSettings')}}</span>
                  </label>
                </div>
                <div class="item-list">
                  <div class="item_list" key="all">
                    <div
                      class="item employee"
                      v-if="select_status === 'employee'"
                    >
                      <input
                        id="allOption"
                        type="checkbox"
                        v-model="all"
                        @click="allLeftOption"
                      />
                      <label for="allOption">{{i18n('Custom.SelectAll')}}</label>
                    </div>
                  </div>
                  <div
                    class="item_list"
                    v-for="employee in persons"
                    :key="`${employee.EmpCName} / ${employee.EmpNo} / ${employee.DeptId}`"
                  >
                    <div
                      class="item employee"
                      v-if="select_status === 'employee'"
                    >
                      <input
                        :id="employee.EmpCName"
                        type="checkbox"
                        :value="employee"
                        v-model="select"
                      />
                      <label :for="employee.EmpCName"
                        >{{ employee.EmpCName }} / {{ employee.EmpNo }} /
                        {{ employee.TitleName }} /
                        {{ employee.DeptSName }}</label
                      >
                    </div>
                  </div>

                  <div
                    class="item_list"
                    v-for="setting in options"
                    :key="setting.id"
                  >
                    <div
                      class="item setting"
                      v-if="select_status === 'commonly'"
                    >
                      <input
                        :id="setting.Guid"
                        type="radio"
                        :value="setting"
                        v-model="selectOption"
                      />
                      <!-- <label class="setting" :for="setting.name">{{setting.name}}</label> -->
                      <label class="setting" :for="setting.Guid">
                        <span v-if="setting.EmpCName"
                          >{{ setting.EmpCName }}/</span
                        >
                        <span v-if="setting.AgeMin"
                          >{{i18n('Basic.Age')}}:{{ setting.AgeMin }}~
                          {{ setting.AgeMax }}/</span
                        >
                        <span v-if="setting.SeniorityMin"
                          >{{i18n('Custom.Seniority')}}:{{ setting.SeniorityMin }}~
                          {{ setting.SeniorityMax }}/</span
                        >
                        <span v-if="setting.BTEXT">
                          <span v-for="range in setting.BTEXT" :key="range"
                            >{{ range }}/</span
                          >
                        </span>
                        <span v-if="setting.DeptSName">
                          <span v-for="dept in setting.DeptSName" :key="dept"
                            >{{ dept }}/</span
                          >
                        </span>
                        <span v-if="setting.CompName">
                          <span v-for="comp in setting.CompName" :key="comp"
                            >{{ comp }}/</span
                          >
                        </span>
                        <span v-if="setting.TitleName">
                          <span
                            v-for="titleName in setting.TitleName"
                            :key="titleName"
                            >{{ titleName }}/</span
                          >
                        </span>
                        <span v-if="setting.Post">
                          <span v-for="post in setting.Post" :key="post">{{
                            post
                          }}</span>
                        </span>
                      </label>
                      <button
                        class="common-btn"
                        @click.prevent="removeOption(setting.Guid)"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="center-item">
                <span class="add-item" @click="addClick"> → </span>
                <span class="remove-item" @click="removeClick"> ← </span>
              </div>
              <div id="right-item">
                <!-- <div class="button-wrap right" style="position:relative; top:-3px;">
                                            45
                                        </div> -->
                <span class="item-type"
                  >{{i18n('Custom.Chosen')}}
                  <span class="text-danger"
                    >({{ selecteds.length }})</span
                  ></span
                >

                <div class="item-list">

                  <div class="item_list" key="all" v-if="selecteds.length > 0">
                    <div class="item">
                      <input
                        id="allRightOption"
                        type="checkbox"
                        v-model="allSeleted"
                        @click="allRightOption"
                      />
                      <label for="allRightOption">{{i18n('Custom.SelectAll')}}</label>
                    </div>
                  </div>
                  <div
                    class="item_list"
                    v-for="(selected, index) in selecteds"
                    :key="selected.index"
                  >
                    <div class="item">
                      <input
                        :id="index"
                        type="checkbox"
                        :value="index"
                        v-model="select"
                      />
                      <!-- <i class="fas fa-check-square"></i> -->
                      <label :for="index"
                        >{{ selected.EmpCName }} / {{ selected.EmpNo }} /
                        {{ selected.TitleName }} /
                        {{ selected.DeptSName }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="cancel"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-blue"
              data-dismiss="modal"
              @click.prevent="submitPerson"
            >
              {{i18n('Basic.Save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-blue {
  color: #fff !important;
  // font-size: .85em;
  background: #343b55;
  border-radius: 6px;
  height: 38px;
}

.btn-yellow {
  background: #cca836;
  color: #fff;
}

.right {
  display: block;
  text-align: right;
}

.filter-btn-wrap {
  margin-top: 8px;
}

.option {
  margin-left: 0.2rem;
  cursor: pointer;
}
.item_list {
  margin-bottom: 5px;
}
.item-select #left-item .item-list .item,
.item-select #right-item .item-list .item {
  display: flex;
  align-items: center;
}
.item-select #left-item .item-list .item input[type="checkbox"],
.item-select #right-item .item-list .item input[type="checkbox"] {
  display: block;
  margin-right: 0.5rem;
}

.item-select #left-item .item-list .item input[type="radio"] {
  display: block;
  margin-right: 0.5rem;
}

.filter-block .filter-field .flex > .age-input,
.filter-block .filter-field .flex > .year-input {
  width: 50px;
}

.white-overlay {
  background-color: rgba(0, 255, 255, 0.3);
}

.filter-block .filter-field .flex {
  align-items: center;
}

#right-item {
  margin-top: 37px;
}

.setting {
  text-overflow: ellipsis;
}

.item-select #right-item .item-type {
  background-color: #eceef6;
  color: #33344a;
  padding: 3px 10px;
  position: absolute;
  top: -25px;
  left: 10px;
}

.v-select {
  min-width: 120px;
  height: 36px;
  line-height: 36px;
  background-color: #fff;
}

.common-btn {
  border: none;
  background-color: transparent;
}
</style>

<script>
import $ from 'jquery';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['selectPersons','departments','isInterviewer'],
  data() {
    return {
      screen: null, // loading遮罩
      select_status: 'employee',
      all: false,
      allSeleted: false,
      select: [],
      selectOption: {},
      selecteds: [],


      optionInfo: {
        CompName:[],
      },
    };
  },
  computed: {
    // ...mapGetters('personModule', ['persons']),
    ...mapGetters('personModule', [
      'persons',
      'post',
      'compName',
      'deptName',
      'r2Id',
      'titleName',
      'options',
    ]),
  },
  methods: {
    ...mapActions(['updateLoading']),
    ...mapActions('personModule', ['getOption']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    addClick() {
      this.selecteds = [...this.selecteds, ...this.select];
      const set = new Set();
      this.selecteds = this.selecteds.filter((item) => (!set.has(`${item.CompId}${item.EmpNo}`)
        ? set.add(`${item.CompId}${item.EmpNo}`)
        : false));
      this.select = [];
      this.selectOption = {};
      this.all = false;
    },
    removeClick() {
      this.select.forEach((item, index) => {
        if (index === 0) {
          this.selecteds.splice(item, 1);
        } else {
          this.selecteds.splice(item - index, 1);
        }

        // this.selecteds.splice(item, 1)
      });

      this.select = [];
      this.allSeleted = false;

      // this.selecteds.splice(this.select.filter((el, index) => {
      //     return this.select.findIndex(($el) => {
      //         return el.id === $el.id
      //     }) === index
      // }), this.select.length)
    },
    submitPerson() {
      this.$emit('batchPerson', this.selecteds);
      this.selecteds = [];
    },
    searchPerson() {
      // 原本的const url = `${window.BaseUrl.api}/personelsys/range`;
      const url = `${window.BaseUrl.api}/Org/search`;

      const params = {
        ...this.optionInfo,
      };

      console.log('search', params);

      this.updateLoading(true);
      this.$http
        .post(url, params)
        .then((response) => {
          console.log(response.data);
          this.select_status = 'employee';
          this.$store.commit('personModule/PERSON', response.data.Data);
          this.all = true;
          this.select = response.data.Data; // 查詢的資料帶入全選
          this.updateLoading(false);
        })
        .catch(() => {
          this.updateLoading(false);
        });

      this.selectOption = {};
    },
    clearSearch() {
      Object.keys(this.optionInfo).forEach(
        // eslint-disable-next-line no-return-assign
        (key) => (this.optionInfo[key] = ''),
      );
      this.select = [];
      this.all = false;
      this.optionInfo = {
        CompName:[],
      };
    },
    addOption() {
      // const url = `${window.BaseUrl.api}/commonconditions/add`;
      const url = `${window.BaseUrl.api}/Org/SaveSearchConditions`;

      const obj = {
        // CompId: "0001",
        // DeptId: "00000001",
        // EmpNo: "00000021",
        CompId: localStorage.CompId,
        DeptId: localStorage.DeptId,
        EmpNo: localStorage.EmpNo,
        ...this.optionInfo,
      };

      // console.log('obj', obj)

      // this.updateLoading(true)
      this.$http.post(url, obj).then((response) => {
        console.log(response);
        // this.updateLoading(false)
        this.getOption();
      });
    },
    removeOption(guid) {
      // const url = `${window.BaseUrl.api}/commonconditions/delete`;
      const url = `${window.BaseUrl.api}/Org/SaveSearchConditions`;
      // this.settings.splice(index, 1)

      const params = {
        guid,
      };

      this.$http.delete(url, { params }).then((response) => {
        console.log(response);
        this.getOption();
        // this.updateLoading(false)
      });
    },
    clickOption() {
      this.optionInfo = { ...this.selectOption };
    },
    cancel() {
      this.select = [];
      this.selecteds = [];
    },
    allLeftOption() {
      this.all = !this.all;

      this.screen = this.openLoading();
      // (function() {
      //     this.updateLoading(true)
      // }())

      if (this.all) {
        // this.select = [...this.persons]

        setTimeout(() => {
          this.select = [...this.persons];
        }, 1000);
        // this.select = this.persons.map(i => {return i.Guid})
        // this.updateLoading(false)
      } else {
        this.select = [];
        // this.updateLoading(false)
      }

      setTimeout(() => {
        this.screen.close();
      }, 1000);
    },
    allRightOption() {
      this.allSeleted = !this.allSeleted;

      this.updateLoading(true);
      if (this.allSeleted) {
        // this.select = [...this.persons]
        // this.select = this.selecteds.map((i, index) => {return index})

        setTimeout(() => {
          this.select = this.selecteds.map((i, index) => index);
        }, 1000);

        // this.updateLoading(false)
      } else {
        this.select = [];
        // this.updateLoading(false)
      }

      setTimeout(() => {
        this.updateLoading(false);
      }, 1000);
    },
  },
  watch: {
    selectOption() {
      if (this.select_status === 'commonly') {
        this.clickOption();
      }
    },
    selectPersons() {
      this.optionInfo = {
        CompName:this.isInterviewer ?['遠東新世紀股份有限公司'] : [],
      };

      if (this.selectPersons) {
        this.selecteds = [...this.selectPersons];
      }
    },
    isInterviewer(e){
      if(e){
        console.log("e",e);
        this.optionInfo.CompName=['遠東新世紀股份有限公司'];
        console.log("this.optionInfo.CompName",this.optionInfo.CompName);
      }else{
        console.log("e",e);
        this.optionInfo.CompName=[]
      }
    }
  },
  created() {
    // this.getPerson()
    // this.getOption()
    // this.getSetting()
    // Multiple modals overlay z-index fix
    $(document).on('show.bs.modal', '.modal', function () {
      const zIndex = 1040 + 10 * $('.modal:visible').length;
      $(this).css('z-index', zIndex);
      setTimeout(() => {
        $('.modal-backdrop')
          .not('.modal-stack')
          .css('z-index', zIndex - 1)
          .addClass('modal-stack');
      }, 0);
    });
  },
};
</script>
